import classes from './ErrorPage.module.css';

import { Button, Container, Group, Stack, Text, Title } from '@mantine/core';
import type { ReactNode } from 'react';

import { Link } from '~/features/links';

interface ErrorPageProps {
  title: string;
  description?: string;
  errorCode?: ReactNode;
  redirectTo?: string;
}

export function ErrorPage({ title, description, redirectTo = '/', errorCode = '404' }: ErrorPageProps) {
  return (
    <Container className={classes.root}>
      <Stack gap={50}>
        <div className={classes.label}>{errorCode}</div>
        <Title className={classes.title}>{title}</Title>
        {description &&
        <Text c="dimmed" size="lg" ta="center" className={classes.description}>
            {description}
          </Text>}

        {redirectTo &&
        <Group justify="center">
            <Button component={Link} to={redirectTo} variant="subtle" size="md">
              Take me back
            </Button>
          </Group>}

      </Stack>
    </Container>);

}